import { baseApi } from '@transova/rtk';

import { ITransfersState } from '../reducers/transfersSlice';

export const transfersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTransfers: build.query<ITransfersState[], string>({
      query: (clientId) => `transfers/${clientId}`,
      transformResponse: (response: ITransfersState[]) => response || [],
      providesTags: ['Transfers'],
    }),
  }),
});

export const { useGetTransfersQuery } = transfersApi;
