import { baseApi } from '@transova/rtk';

export type SearchUser = {
  id: string;
  username: string;
  email: string;
  ranchCode: string;
};

export type UserInput = {
  adUserId?: string;
  username?: string;
  ranchCode?: string;
  email?: string;
  isCustomerService: boolean;
  isSuperAdmin: boolean;
  isInIT: boolean;
  isMyOpuEntry: boolean;
  isMyTransferEntry: boolean;
  moveStatementPdf: boolean;
  hideInvoicesStatements: boolean;
  sites?: string[];
  isActive: boolean;
};

export type User = {
  id: number;
  adUserId: string | null;
  username: string;
  ranchCode: string;
  email: string;
  stateCode: number;
  perPage: number | null;
  isCustomerService: boolean;
  isSuperAdmin: boolean;
  isMyOpuEntry: boolean | null;
  isMyTransferEntry: boolean | null;
  moveStatementPdf: boolean;
  isInIT: boolean;
  showPa: boolean;
  showVit: boolean;
  useNewSite: boolean;
  useNewSiteStopReminder: boolean;
  hideInvoicesStatements: boolean;
  sites: Array<{ id: number; siteId: string; site: string; userId: number }>;
};

export type AdUser = {
  id: string;
  username: string;
  email: string;
  name: string;
};

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createUser: build.mutation<User, UserInput>({
      query: (user) => ({ url: 'user', method: 'POST', body: user }),
    }),
    updateUser: build.mutation<boolean, { id: number; user: UserInput }>({
      query: ({ id, user }) => ({
        url: `user/${id}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
    possibleUsers: build.query<AdUser[], void>({
      query: () => 'user/active-directory/possible-users',
    }),
    allUsers: build.query<AdUser[], void>({
      query: () => 'user/active-directory/all',
    }),
    searchUsers: build.query<Array<SearchUser>, string>({
      query: (query) => `user/search?q=${query}`,
    }),
    getUser: build.query<User, string>({
      query: (id) => `user/${id}`,
      providesTags: ['User'],
    }),
  }),
});

export const {
  useCreateUserMutation,
  usePossibleUsersQuery,
  useLazySearchUsersQuery,
  useGetUserQuery,
  useAllUsersQuery,
  useUpdateUserMutation,
} = userApi;
