import { baseApi } from '@transova/rtk';

export type Site = {
  id: string;
  createdOn: string | null;
  createdBy: string | null;
  modifiedOn: string | null;
  modifiedBy: string | null;
  createdOnBehalfBy: string | null;
  modifiedOnBehalfBy: string | null;
  organizationId: string | null;
  overriddenCreatedOn: string | null;
  name: string;
  emailAddress: string | null;
};

export const sitesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSites: build.query<Site[], void>({
      query: () => 'site/all',
      providesTags: ['Sites'],
    }),
  }),
});

export const { useGetSitesQuery } = sitesApi;
