export const filterParams = {
  suppressAndOrCondition: true,
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    if (cellValue == null) return -1;

    const dateParts = cellValue.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
    const cellDate = new Date(
      Number(dateParts[1]),
      Number(dateParts[2]) - 1,
      Number(dateParts[3]),
    );

    if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
      return -1;
    }

    if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
      return 1;
    }

    return 0;
  },
  browserDatePicker: true,
};
