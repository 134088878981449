import { baseApi } from '@transova/rtk';

import { ICalvesAnticipatedState } from '../reducers';
import { ICalfProceduresForMonthState } from '../reducers/calfProceduresForMonthSlice';
import { ICalfProceduresForThisWeekState } from '../reducers/calfProceduresForThisWeekSlice';
import {
  ICalvesAllState,
  ICalvesCurrentState,
} from '../reducers/calvesAllSlice';

export const calvesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentCalves: build.query<ICalvesCurrentState[], string>({
      query: (clientId) => `calves/${clientId}/current`,
      transformResponse: (response: ICalvesCurrentState[]) => response || [],
      providesTags: ['Calves'],
    }),
    getAllCalves: build.query<ICalvesAllState[], string>({
      query: (clientId) => `calves/${clientId}`,
      transformResponse: (response: ICalvesAllState[]) => response || [],
      providesTags: ['Calves'],
    }),
    getAnticipatedCalves: build.query<ICalvesAnticipatedState[], string>({
      query: (clientId) => `calves/${clientId}/anticipated`,
      transformResponse: (response: ICalvesAllState[]) => response || [],
      providesTags: ['Calves'],
    }),
    getCalfProceduresForThisWeek: build.query<
      ICalfProceduresForThisWeekState[],
      string
    >({
      query: (clientId) => `calves/${clientId}/procedures/this-week`,
      transformResponse: (response: ICalvesAllState[]) => response || [],
      providesTags: ['Calves'],
    }),
    getCalfProceduresForThisMonth: build.query<
      ICalfProceduresForMonthState[],
      string
    >({
      query: (clientId) => `calves/${clientId}/procedures/this-month`,
      transformResponse: (response: ICalvesAllState[]) => response || [],
      providesTags: ['Calves'],
    }),
    getCalfProceduresForLastMonth: build.query<
      ICalfProceduresForMonthState[],
      string
    >({
      query: (clientId) => `calves/${clientId}/procedures/last-month`,
      transformResponse: (response: ICalvesAllState[]) => response || [],
      providesTags: ['Calves'],
    }),
  }),
});

export const {
  useGetCurrentCalvesQuery,
  useGetAllCalvesQuery,
  useGetAnticipatedCalvesQuery,
  useGetCalfProceduresForThisWeekQuery,
  useGetCalfProceduresForThisMonthQuery,
  useGetCalfProceduresForLastMonthQuery,
} = calvesApi;
