import { baseApi } from '@transova/rtk';

import { IFrozenShipmentsState } from '../reducers/frozenShipmentsSlice';

export const frozenShipmentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFrozenShipments: build.query<IFrozenShipmentsState[], string>({
      query: (clientId) => `frozen-shipments/${clientId}`,
      transformResponse: (response: IFrozenShipmentsState[]) => response || [],
      providesTags: ['FrozenShipments'],
    }),
  }),
});

export const { useGetFrozenShipmentsQuery } = frozenShipmentsApi;
