import { decode } from 'html-entities';

import { baseApi } from '@transova/rtk';

export type Report = {
  accountCode: string;
  externalSaveName: string | null;
  folderId: number;
  id: number;
  internalSaveName: string;
  isEnabled: boolean;
  name: string;
  number: number;
  reportFolderId: number;
  reportOutputTypeId: number;
  templateName: string;
};

export type ReportId = { reportId: string };

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<Array<Report>, string>({
      query: (accountCode) =>
        `reports/${encodeURIComponent(decode(accountCode))}`,
      providesTags: ['Reports'],
    }),
    emailPdf: build.mutation<void, { path: string; accountCode: string }>({
      query: (body) => ({
        url: 'reports/pdf/email',
        method: 'POST',
        body,
      }),
    }),
    pdfReport: build.mutation<ReportId, { path: string }>({
      query: (body) => ({ url: 'reports/pdf', method: 'POST', body }),
    }),

    animalShipmentReport: build.mutation<
      ReportId,
      { accountId: string; shipmentCode: string }
    >({
      query: (body) => ({
        url: 'reports/animal-shipment',
        method: 'POST',
        body,
      }),
    }),
    frozenShipmentReport: build.mutation<
      ReportId,
      { accountCode: string; shipmentCode: string }
    >({
      query: (body) => ({
        url: 'reports/frozen-shipment',
        method: 'POST',
        body,
      }),
    }),
    calfHistoryReport: build.mutation<ReportId, { calfId: string }>({
      query: (body) => ({ url: 'reports/calf-history', method: 'POST', body }),
    }),
    abcFlushReport: build.mutation<
      ReportId,
      { flushId: string; mating: string }
    >({
      query: (body) => ({ url: 'reports/abc-flush', method: 'POST', body }),
    }),
    freshShipmentReport: build.mutation<
      ReportId,
      { accountCode: string; shipmentCode: string }
    >({
      query: (body) => ({
        url: 'reports/fresh-shipment',
        method: 'POST',
        body,
      }),
    }),
    implantFormReport: build.mutation<
      ReportId,
      { accountCode: string; shipmentCode: string }
    >({
      query: (body) => ({ url: 'reports/implant-form', method: 'POST', body }),
    }),
    invoiceFormReport: build.mutation<
      ReportId,
      { accountCode: string; invoiceCode: string }
    >({
      query: (body) => ({ url: 'reports/invoice', method: 'POST', body }),
    }),
    abcIvfPoolReport: build.mutation<
      ReportId,
      { fertilizationId: string; mating: string; aspirationPoolId: string }
    >({
      query: (body) => ({ url: 'reports/abc-ivf-pool', method: 'POST', body }),
    }),
    abcIvfFertReport: build.mutation<
      ReportId,
      { fertilizationId: string; mating: string; aspirationId: string }
    >({
      query: (body) => ({ url: 'reports/abc-ivf-fert', method: 'POST', body }),
    }),
    edonorIvfProductionSummary: build.mutation<
      ReportId,
      { accountId: string; aspirationDate: string }
    >({
      query: (body) => ({
        url: 'reports/edonor/ivf-production-summary',
        method: 'POST',
        body,
      }),
    }),
    edonorEmbryos: build.mutation<ReportId, { shipmentCode: string }>({
      query: (body) => ({
        url: 'reports/edonor/embryos',
        method: 'POST',
        body,
      }),
    }),
    edonorEmbryosByDate: build.mutation<
      ReportId,
      { aspirationDate: string; accountCode: string }
    >({
      query: (body) => ({
        url: 'reports/edonor/embryos-by-date',
        method: 'POST',
        body,
      }),
    }),
    invoiceDetailReport: build.mutation<
      ReportId,
      { accountCode: string; startDate: string; endDate: string }
    >({
      query: (body) => ({
        url: 'reports/tog-invoice-detail',
        method: 'POST',
        body,
      }),
    }),
    TransferReport: build.mutation<
      ReportId,
      {
        startDate: string;
        endDate: string;
        acctNum: string;
        opuSite: string;
        implantSite: string;
      }
    >({
      query: (body) => ({
        url: 'reports/transfer',
        method: 'POST',
        body,
      }),
    }),
    AspirationScheduleReport: build.mutation<
      ReportId,
      {
        ASPDate: string;
        AcctNum: string;
      }
    >({
      query: (body) => ({
        url: 'reports/aspiration-schedule',
        method: 'POST',
        body,
      }),
    }),
    CalvesReport: build.mutation<
      ReportId,
      {
        FromDate: string;
        Client: string;
      }
    >({
      query: (body) => ({
        url: 'reports/calves',
        method: 'POST',
        body,
      }),
    }),
    SummaryBySireReport: build.mutation<
      ReportId,
      {
        startDate: string;
        endDate: string;
        acctNum: string;
      }
    >({
      query: (body) => ({
        url: 'reports/summary-by-sire',
        method: 'POST',
        body,
      }),
    }),
    invoiceDetailWithPaymentReport: build.mutation<
      ReportId,
      { accountCode: string; startDate: string; endDate: string }
    >({
      query: (body) => ({
        url: 'reports/tog-invoice-detail-with-payment',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAbcFlushReportMutation,
  useAbcIvfFertReportMutation,
  useAbcIvfPoolReportMutation,
  useAnimalShipmentReportMutation,
  useCalfHistoryReportMutation,
  useEdonorEmbryosByDateMutation,
  useEdonorEmbryosMutation,
  useEdonorIvfProductionSummaryMutation,
  useEmailPdfMutation,
  useFreshShipmentReportMutation,
  useFrozenShipmentReportMutation,
  useGetReportsQuery,
  useImplantFormReportMutation,
  useInvoiceDetailReportMutation,
  useTransferReportMutation,
  useAspirationScheduleReportMutation,
  useCalvesReportMutation,
  useSummaryBySireReportMutation,
  useInvoiceDetailWithPaymentReportMutation,
  useInvoiceFormReportMutation,
  usePdfReportMutation,
} = reportsApi;
