import { baseApi } from '@transova/rtk';

import { IAnimalShipmentsState } from '../reducers/animalShipmentsSlice';

export const animalShipmentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAnimalShipments: build.query<IAnimalShipmentsState[], string>({
      query: (clientId) => `animal-shipments/${clientId}`,
      transformResponse: (response: IAnimalShipmentsState[]) => response || [],
      providesTags: ['AnimalShipments'],
    }),
  }),
});

export const { useGetAnimalShipmentsQuery } = animalShipmentsApi;
