import { useCallback, useEffect, useState } from 'react';

export enum ScreenSize {
  MOBILE_WIDTH = 576,
  TABLET_WIDTH = 768,
}

export function useViewportSize() {
  const { MOBILE_WIDTH, TABLET_WIDTH } = ScreenSize;

  interface IDeviceInfo {
    width: number;
    height: number;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  }

  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo>({
    width: document.documentElement.clientWidth || window.innerWidth,
    height: document.documentElement.clientHeight || window.innerHeight,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const handleResize = useCallback(() => {
    const width = document.documentElement.clientWidth || window.innerWidth;
    const height = document.documentElement.clientHeight || window.innerHeight;

    setDeviceInfo({
      width: width,
      height: height,
      isMobile: width <= MOBILE_WIDTH,
      isTablet: width > MOBILE_WIDTH && width <= TABLET_WIDTH,
      isDesktop: width > TABLET_WIDTH,
    });
  }, [MOBILE_WIDTH, TABLET_WIDTH]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return deviceInfo;
}
