import { baseApi } from '@transova/rtk';

import { IDonorsState } from '../reducers/donorsSlice';

export const donorsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDonors: build.query<IDonorsState[], string>({
      query: (clientId) => `donors/${clientId}`,
      transformResponse: (response: IDonorsState[]) => response || [],
      providesTags: ['Donors'],
    }),
  }),
});

export const { useGetDonorsQuery } = donorsApi;
