import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './spinnerState';

export const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    setIgnoreRequestsFrom: (state, action) => {
      if (!action.payload) return state;

      return {
        ...state,
        ignoreRequestsFrom: action.payload,
      };
    },
    addRequest: (state, action) => {
      if (!action.payload) return state;

      return {
        ...state,
        pending: [...state.pending, action.payload],
      };
    },
    removeRequest: (state, action) => {
      if (!action.payload) return state;

      return {
        ...state,
        pending: state.pending.filter((id: string) => action.payload !== id),
      };
    },
  },
});

export const { addRequest, removeRequest, setIgnoreRequestsFrom } =
  spinnerSlice.actions;

export default spinnerSlice.reducer;
