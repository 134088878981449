import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useCustomViewUsage() {
  const url = useLocation();
  return useMemo(() => {
    const path = url.pathname.replace(/^\//gm, '');
    const keys = ['primary', 'secondary', 'tertiary', 'quaternary'];
    const values = path.split('/');
    const parts = [...values].map(
      (a, index) => `${keys[index]}=${values[index]}`,
    );
    const pathQueryString = parts.join('&');
    const searchQueryString = url.search ? url.search.replace(/^\?/, '') : '';
    const usage = `${pathQueryString}&${searchQueryString}`;

    return usage;
  }, [url.pathname, url.search]);
}
