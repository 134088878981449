import { baseApi } from '@transova/rtk';

import { IClientState } from './clientApi';

export const ranchesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRanchesByUserId: build.query<IClientState[], number>({
      query: (id) => `ranches/ranchesByUserId/${id}`,
      providesTags: ['Ranches'],
    }),
  }),
});

export const { useGetRanchesByUserIdQuery } = ranchesApi;
