import { decode } from 'html-entities';

import { baseApi } from '@transova/rtk';

import { IInvoiceFinancialsState } from '../reducers/invoiceFinancialsSlice';
import { IInvoicesAllState } from '../reducers/invoicesAllSlice';
import { IInvoicesByDonorState } from '../reducers/invoicesByDonorSlice';

export const invoicesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllInvoices: build.query<
      IInvoicesAllState[],
      { clientCode: string; startDate: string; endDate: string }
    >({
      query: (arg) =>
        `invoices/${encodeURIComponent(
          decode(arg.clientCode),
        )}/summaries?startDate=${arg.startDate}&endDate=${arg.endDate}`,
      transformResponse: (response: IInvoicesAllState[]) => response || [],
      providesTags: ['InvoicesAll'],
    }),
    getInvoicesByDonor: build.query<
      IInvoicesByDonorState[],
      { clientCode: string; startDate: string; endDate: string }
    >({
      query: (arg) =>
        `invoices/${encodeURIComponent(
          decode(arg.clientCode),
        )}/by-donor?startDate=${arg.startDate}&endDate=${arg.endDate}`,
      transformResponse: (response: IInvoicesByDonorState[]) => response || [],
      providesTags: ['InvoicesByDonor'],
    }),
    getInvoiceFinancials: build.query<
      IInvoiceFinancialsState[],
      string | undefined
    >({
      query: (clientCode) =>
        `invoices/${encodeURIComponent(decode(clientCode))}/financials`,
      transformResponse: (response: IInvoiceFinancialsState[]) =>
        response || [],
      providesTags: ['InvoiceFinancials'],
    }),
  }),
});

export const {
  useGetAllInvoicesQuery,
  useGetInvoicesByDonorQuery,
  useGetInvoiceFinancialsQuery,
} = invoicesApi;
