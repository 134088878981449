export enum StatusCodes {
  STATUS_100 = 'Continue',
  STATUS_101 = 'Switching Protocols',
  STATUS_102 = 'Processing',
  STATUS_103 = 'Early Hints',
  STATUS_200 = 'OK',
  STATUS_201 = 'Created',
  STATUS_202 = 'Accepted',
  STATUS_203 = 'Non-Authoritative Information',
  STATUS_204 = 'No Content',
  STATUS_205 = 'Reset Content',
  STATUS_206 = 'Partial Content',
  STATUS_207 = 'Multi-Status',
  STATUS_208 = 'Already Reported',
  STATUS_226 = 'IM Used',
  STATUS_300 = 'Multiple Choices',
  STATUS_301 = 'Moved Permanently',
  STATUS_302 = 'Found',
  STATUS_303 = 'See Other',
  STATUS_304 = 'Not Modified',
  STATUS_305 = 'Use Proxy',
  STATUS_306 = '(Unused)',
  STATUS_307 = 'Temporary Redirect',
  STATUS_308 = 'Permanent Redirect',
  STATUS_400 = 'Bad Request',
  STATUS_401 = 'Unauthorized',
  STATUS_402 = 'Payment Required',
  STATUS_403 = 'Forbidden',
  STATUS_404 = 'Not Found',
  STATUS_405 = 'Method Not Allowed',
  STATUS_406 = 'Not Acceptable',
  STATUS_407 = 'Proxy Authentication Required',
  STATUS_408 = 'Request Timeout',
  STATUS_409 = 'Conflict',
  STATUS_410 = 'Gone',
  STATUS_411 = 'Length Required',
  STATUS_412 = 'Precondition Failed',
  STATUS_413 = 'Content Too Large',
  STATUS_414 = 'URI Too Long',
  STATUS_415 = 'Unsupported Media Type',
  STATUS_416 = 'Range Not Satisfiable',
  STATUS_417 = 'Expectation Failed',
  STATUS_421 = 'Misdirected Request',
  STATUS_422 = 'Unprocessable Content',
  STATUS_423 = 'Locked',
  STATUS_424 = 'Failed Dependency',
  STATUS_425 = 'Too Early',
  STATUS_426 = 'Upgrade Required',
  STATUS_428 = 'Precondition Required',
  STATUS_429 = 'Too Many Requests',
  STATUS_431 = 'Request Header Fields Too Large',
  STATUS_451 = 'Unavailable For Legal Reasons',
  STATUS_500 = 'Internal Server Error',
  STATUS_501 = 'Not Implemented',
  STATUS_502 = 'Bad Gateway',
  STATUS_503 = 'Service Unavailable',
  STATUS_504 = 'Gateway Timeout',
  STATUS_505 = 'HTTP Version Not Supported',
  STATUS_506 = 'Variant Also Negotiates',
  STATUS_507 = 'Insufficient Storage',
  STATUS_508 = 'Loop Detected',
  STATUS_510 = 'Not Extended (OBSOLETED)',
  STATUS_511 = 'Network Authentication Required',

  STATUS_FETCH_ERROR = 'Failed To Fetch',
}
