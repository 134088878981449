import { baseApi } from '@transova/rtk';

import { IFreshShipmentsState } from '../reducers/freshShipmentsSlice';

export const freshShipmentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFreshShipments: build.query<IFreshShipmentsState[], string>({
      query: (clientId) => `fresh-shipments/${clientId}`,
      transformResponse: (response: IFreshShipmentsState[]) => response || [],
      providesTags: ['FreshShipments'],
    }),
  }),
});

export const { useGetFreshShipmentsQuery } = freshShipmentsApi;
