import { useCallback, useEffect, useState } from 'react';

export function useDarkMode() {
  const mediaSlug = '(prefers-color-scheme: dark)';

  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    window.matchMedia(mediaSlug).matches,
  );

  const handleModeChange = useCallback(() => {
    setIsDarkMode(window.matchMedia(mediaSlug).matches);
  }, []);

  useEffect(() => {
    window.matchMedia(mediaSlug).addEventListener('change', handleModeChange);

    handleModeChange();

    return () => {
      window
        .matchMedia(mediaSlug)
        .removeEventListener('change', handleModeChange);
    };
  }, [handleModeChange]);

  return isDarkMode;
}
