import { encode } from 'html-entities';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { baseApi } from '@transova/rtk';

export type IClientState = { id: string; name: string; code: string };

export const clientApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    searchClients: build.query<IClientState[], string>({
      query: (code) => `client/search?client=${encodeURIComponent(code)}`,
      transformResponse: (response: IClientState[]) => response || [],
      providesTags: ['Client'],
    }),
    allRanches: build.query<string[], void>({
      query: () => 'Ranches/all',
      transformResponse: (response: string[]) => response || [],
      providesTags: ['Ranches'],
    }),
    getClient: build.query<IClientState, string>({
      query: (code) => `Client/code?client=${encodeURIComponent(code)}`,
      transformResponse: (response: IClientState) => {
        const result = {
          ...response,
          code: encode(response.code),
        };
        return result;
      },
      providesTags: ['Client'],
    }),
  }),
});

export const { useSearchClientsQuery, useAllRanchesQuery, useGetClientQuery } =
  clientApi;

export function useCurrentAccountCode() {
  const [params, setParams] = useSearchParams();
  const ranchCode = params.get('ranch');
  const setRanchCode = useCallback(
    (ranchCode: string) => setParams({ ranch: ranchCode }),
    [setParams],
  );

  return [ranchCode || undefined, setRanchCode] as const;
}
export function useCurrentAccount() {
  const [ranchCode, setRanchCode] = useCurrentAccountCode();
  const ranch = useGetClientQuery(ranchCode ?? '', { skip: !ranchCode });

  return [ranch, setRanchCode] as const;
}
