import { baseApi } from '@transova/rtk';

import { IFlushProductionSummaryState } from '../reducers';

export const flushProductionSummaryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFlushProductionSummaryByClient: build.query<
      IFlushProductionSummaryState[],
      string
    >({
      query: (clientId) =>
        `production-summary/flush-production-summary/${clientId}`,
      transformResponse: (response: IFlushProductionSummaryState[]) =>
        response || [],
      providesTags: ['FlushProductionSummary'],
    }),
  }),
});

export const { useGetFlushProductionSummaryByClientQuery } =
  flushProductionSummaryApi;
