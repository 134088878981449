import { Modals } from '@generouted/react-router/lazy';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useViewportSize } from '@transova/hooks';
import { useMeQuery, useRanches } from '@transova/rtk';

import { useAppSelector } from '../app/hooks';
import { Spinner } from '../features/spinner/Spinner';
import { useCurrentAccountCode } from '../services';
import './_app.scss';

function App() {
  const { currentData: me, isLoading: meLoading } = useMeQuery();

  const location = useLocation();
  const navigate = useNavigate();

  const showSpinner = useAppSelector(
    (state) => state.spinner.pending.length > 0,
  );

  const deviceInfo = useViewportSize();
  useEffect(() => {
    document.body.setAttribute(
      'data-is-mobile',
      String(deviceInfo.width <= 600),
    );
  }, [deviceInfo.width]);

  const parts = useMemo(
    () => location.pathname.split('/'),
    [location.pathname],
  );
  useEffect(() => {
    const pathnameClass = parts
      .filter((s) => s.length)
      .join('-')
      .toLowerCase();
    document.body.setAttribute('data-path', pathnameClass);
    document.body.setAttribute('data-primary', parts[1]?.toLowerCase() ?? '');
    document.body.setAttribute('data-secondary', parts[2]?.toLowerCase() ?? '');
  }, [parts]);

  const ranches = useRanches();
  const [accountCode] = useCurrentAccountCode();
  useEffect(() => {
    const notAdUser = !!(me && accountCode && !me?.adUserId);
    const notClientsRanch = !(
      ranches && ranches.some((r) => r.code === accountCode)
    );

    if (notAdUser && notClientsRanch && location.pathname !== '/User/Logout') {
      navigate({
        search: new URLSearchParams({ ranch: me.accountCode }).toString(),
      });
    }

    if (location.pathname !== '/' && accountCode) {
      return;
    }
    if (!me) {
      return;
    }

    const search = me.adUserId
      ? undefined
      : new URLSearchParams({ ranch: me.accountCode }).toString();
    if (me.adUserId) {
      navigate({ pathname: '/Account/Clients', search }, { replace: true });
    } else {
      navigate({ pathname: '/Home/Dashboard', search }, { replace: true });
    }
  }, [me, accountCode, navigate, location.pathname, location.search, ranches]);

  if (meLoading && !location.pathname.startsWith('/User')) {
    return null;
  }

  if (!me && !location.pathname.startsWith('/User')) {
    return <Navigate to="/User/Login" />;
  }

  return (
    <>
      <div className="App">
        <Outlet />
      </div>
      {showSpinner ? <Spinner /> : null}
      <Modals />
    </>
  );
}

export default App;
