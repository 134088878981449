import { baseApi } from '@transova/rtk';

export const kpiApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    aspirationsYtd: build.query<
      Array<{ aspirations: number; year: number; month: number }>,
      string
    >({
      query: (accountCode) =>
        `kpi/aspirations-ytd?accountCode=${encodeURIComponent(accountCode)}`,
      providesTags: ['Kpi'],
    }),
    cleavageRateYtd: build.query<number, string>({
      query: (accountCode) =>
        `kpi/cleavage-rate-ytd?accountCode=${encodeURIComponent(accountCode)}`,
      providesTags: ['Kpi'],
    }),
    edrYtd: build.query<number, string>({
      query: (accountCode) =>
        `kpi/edr-ytd?accountCode=${encodeURIComponent(accountCode)}`,
      providesTags: ['Kpi'],
    }),
    edrYtdBySemenType: build.query<
      Array<{ type: string; edr: number; percentage: number }>,
      string
    >({
      query: (accountCode) =>
        `kpi/edr-ytd-by-semen-type?accountCode=${encodeURIComponent(
          accountCode,
        )}`,
      providesTags: ['Kpi'],
    }),
    embryosPerFertilizationYtd: build.query<number, string>({
      query: (accountCode) =>
        `kpi/embryos-per-fertilization-ytd?accountCode=${encodeURIComponent(
          accountCode,
        )}`,
      providesTags: ['Kpi'],
    }),
    embryosTransferredYtd: build.query<number, string>({
      query: (accountCode) =>
        `kpi/embryos-transferred-ytd?accountCode=${encodeURIComponent(
          accountCode,
        )}`,
      providesTags: ['Kpi'],
    }),
    embryosFrozenYtd: build.query<number, string>({
      query: (accountCode) =>
        `kpi/embryos-frozen-ytd?accountCode=${encodeURIComponent(accountCode)}`,
      providesTags: ['Kpi'],
    }),
    embryosShippedYtd: build.query<number, string>({
      query: (accountCode) =>
        `kpi/embryos-shipped-ytd?accountCode=${encodeURIComponent(
          accountCode,
        )}`,
      providesTags: ['Kpi'],
    }),
  }),
});

export const {
  useAspirationsYtdQuery,
  useCleavageRateYtdQuery,
  useEdrYtdQuery,
  useEdrYtdBySemenTypeQuery,
  useEmbryosPerFertilizationYtdQuery,
  useEmbryosFrozenYtdQuery,
  useEmbryosShippedYtdQuery,
  useEmbryosTransferredYtdQuery,
} = kpiApi;
