import Logo from '@images/logo_transova.svg?react';
import { useEffect } from 'react';

import { useAppDispatch } from '../../app/hooks';
import './Spinner.scss';
import { setIgnoreRequestsFrom } from './spinnerSlice';

export interface ISpinnerProps {
  ignoreRequestsFrom?: string[];
}

export const Spinner = ({ ignoreRequestsFrom }: ISpinnerProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIgnoreRequestsFrom(ignoreRequestsFrom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="spinner">
      <Logo />
    </div>
  );
};
