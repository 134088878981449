import { baseApi } from '@transova/rtk';

import { IEmbryoInventoryAllState } from '../reducers/embryoInventoryAllSlice';
import { IEmbryoInventoryByMatingState } from '../reducers/embryoInventoryByMatingSlice';

export const embryoInventoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllEmbryos: build.query<IEmbryoInventoryAllState[], string>({
      query: (clientId) => `embryo-inventory/all/${clientId}`,
      transformResponse: (response: IEmbryoInventoryAllState[]) =>
        response || [],
      providesTags: ['EmbryoInventoryAll'],
    }),
    sellEmbryos: build.mutation<
      void,
      { accountId: string; buyer: string; embryoIds: string[] }
    >({
      query: (body) => ({
        url: 'embryo-inventory/sell-embryos',
        method: 'POST',
        body,
      }),
      transformResponse: (response: any) => response || [],
      invalidatesTags: ['EmbryoInventoryAll'],
    }),
    getEmbryosByMating: build.query<IEmbryoInventoryByMatingState[], string>({
      query: (clientId) => `embryo-inventory/distinct-with-detail/${clientId}`,
      transformResponse: (response: IEmbryoInventoryByMatingState[]) =>
        response || [],
      providesTags: ['EmbryoInventoryByMating'],
    }),
  }),
});

export const {
  useGetEmbryosByMatingQuery,
  useGetAllEmbryosQuery,
  useSellEmbryosMutation,
} = embryoInventoryApi;
