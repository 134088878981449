import { decode } from 'html-entities';

import { baseApi } from '@transova/rtk';

import { IStatementsState } from '../reducers/statementsSlice';

export const statementsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStatements: build.query<
      IStatementsState[],
      { clientCode: string; startDate: string; endDate: string }
    >({
      query: (arg) =>
        `statements/account?client=${encodeURIComponent(
          decode(arg.clientCode),
        )}&startDate=${arg.startDate}&endDate=${arg.endDate}`,
      transformResponse: (response: IStatementsState[]) => response || [],
      providesTags: ['Statements'],
    }),
    moveStatementPdf: build.mutation<void, any>({
      query: () => 'statements/move-pdf',
    }),
  }),
});

export const { useGetStatementsQuery, useMoveStatementPdfMutation } =
  statementsApi;
