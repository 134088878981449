import { IShowSpinnerState } from './spinnerTypes';

export const initialState: IShowSpinnerState = {
  pending: [],
  ignoreRequestsFrom: [
    'getAllEmbryos',
    'getCalves',
    'getEmbryosByMating',
    'getDonors',
    'getFlushes',
    'getFlushProductionSummaryByClient',
    'getFreshShipments',
    'getFrozenShipments',
    'getAllInvoices',
    'getInvoicesByDonor',
    'getIvf',
    'getIvfProductionSummaryByClient',
    'getSemenInventory',
    'getTransfers',
    'getViewNames',

    // kpi related queries
    'aspirationsYtd',
    'cleavageRateYtd',
    'edrYtd',
    'edrYtdBySemenType',
    'embryosPerFertilizationYtd',
    'embryosTransferredYtd',
    'embryosFrozenYtd',
    'embryosShippedYtd',

    'getReports',
  ],
};
