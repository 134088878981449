import { baseApi } from '@transova/rtk';

import { IIvfProductionSummaryState } from '../reducers/ivfProductionSummarySlice';

export const ivfProductionSummaryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getIvfProductionSummaryByClient: build.query<
      IIvfProductionSummaryState[],
      string
    >({
      query: (clientId) =>
        `production-summary/ivf-production-summary/${clientId}`,
      transformResponse: (response: IIvfProductionSummaryState[]) =>
        response?.map((item) => {
          return { ...item, fertDate: item.fertDate?.substring(0, 10) };
        }) || [],
      providesTags: ['IvfProductionSummary'],
    }),
  }),
});

export const { useGetIvfProductionSummaryByClientQuery } =
  ivfProductionSummaryApi;
