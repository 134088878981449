import { baseApi } from '@transova/rtk';

import { ISemenInventoryState } from '../reducers/semenInventorySlice';

export const semenInventoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSemenInventory: build.query<
      ISemenInventoryState[],
      { clientId: string; skip?: number; take?: number }
    >({
      query({ clientId, skip, take }) {
        const url = `semen-inventory/${clientId}`;
        const params = new URLSearchParams();
        if (typeof skip === 'number') {
          params.set('skip', skip.toString());
        }
        if (typeof take === 'number') {
          params.set('take', take.toString());
        }

        return `${url}?${params.toString()}`;
      },
      transformResponse: (response: ISemenInventoryState[]) => response || [],
      providesTags: ['SemenInventory'],
    }),
  }),
});

export const { useGetSemenInventoryQuery } = semenInventoryApi;
