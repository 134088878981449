import { ValueFormatterParams } from 'ag-grid-community';

export const formatDate = (value: string | Date | undefined): string => {
  const date = value?.toString();
  if (!date) return '';

  const parts = date.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
  if (parts?.length === 4) {
    return `${parts[2]}/${parts[3]}/${parts[1]}`;
  }

  return '';
};

export const dateFormatter = (params: ValueFormatterParams): string => {
  return formatDate(params.value);
};

export const twoDecimalFormatter = (params: ValueFormatterParams): string => {
  if (!params.value) return '';

  const Decimal = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return Decimal.format(params.value);
};

export const currencyFormatter = (params: ValueFormatterParams): string => {
  if (!params.value) return '';

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
  });

  return USDollar.format(params.value);
};

const percent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
export function percentFormatter<T>({
  value,
}: ValueFormatterParams<T, number>) {
  if (typeof value !== 'number') {
    return '';
  }
  return percent.format(value);
}
